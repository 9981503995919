

.GalleryPhotos  .thumbSwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.GalleryPhotos  .thumbSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


  .thumbSwiper.swiper {
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
}

.GalleryPhotos .thumbSwiper  .swiper-slide {
    background-size: cover;
    background-position: center;
}



.thumbSwiper2 {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.thumbSwiper2 .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.thumbSwiper2 .swiper-slide-thumb-active {
    opacity: 1;
}

.thumbSwiper2 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* @media (min-width: 768px) and (max-width: 991.98px) {
    .thumbSwiper2 .swiper-slide img {
        max-height: 50%;
    }
} */
