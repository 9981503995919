.bus-park-block {
    background: #eab74d;
    color: var(--text-color-white);
    font-weight: 500;
    padding: 40px 0 70px;
}

.custom-me {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.custom-me span {
    color: var(--base-color-green);
    margin-left: 10px;
}

.busParkTitleIcons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;

}

.busParkTitleIcons span {
    font-size: 14px;
}

.busParkTitleIcons div {
    padding-right: 10px;
}

.busParkTitleIcons svg {
    font-size: 18px;
    color: black;
    fill: black;
    width: 25px;
    height: 25px
}

.busParkTitleIcons img {
    width: 25px;
    height: 25px;
}

.email-park .st0 {
    fill: black !important;
}
.bus-park-card.card {
    background: #efc953;
    border: 0;
    border-radius: 0;
}
.bus-park-card .busParkTitleIcons span {
    color: black;
}

.bus-park-card svg {
    color: white;
    fill: white;
    width: 15px;
    height: 15px
}

.bus-park-block h4 {
    font-weight: 700;
    margin-bottom: 50px;
    font-size: 1.25rem;
}

.bus-park-block .swiper-btns {
    position: relative;
    width: 100%;
}

.bus-park-block .swiper-btn.btn-prev {
    position: absolute;
    left: -50px;
    top: 150px;
}

.bus-park-block .swiper-btn.btn-next {
    position: absolute;
    right: -50px;
    top: 150px;
}

@media screen and (max-width: 1024px) {
    .bus-park-block .swiper-btn.btn-prev {
        left: -40px;
    }

    .bus-park-block .swiper-btn.btn-next {
        right: -40px;
    }
}

.bus-park-block .swiper-btn {
    background: #f5dca3;
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;


    position: relative;
    top: 40%;
    margin: 0 auto;
}

.bus-park-block .swiper-slide {
    background: transparent;
}

.bus-park-block .swiper-slide .card {
    /*background: transparent;*/
    height: 100%;
}

.bus-park-block .swiper-slide .card .card-body {
    text-align: left;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bus-park-block .swiper-slide .card .card-title,
.bus-park-block .swiper-slide .card .card-text {
    color: black;
    /* min-height: 48px; */
}

.bus-park-block-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bus-park-block-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bus-park-block div.icon-park {
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.bus-park-block  div.icon-park svg {
    filter: invert(1);
}
.bus-park-block .swiper-slide .link {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 50px;
    width: 70%;
    padding: 5px 20px;
}

.bus-park-block .swiper-slide .link .anticon {
    width: 14px;
}

.card-img-bus-rent {
    max-height: 240px !important;
    height: 240px !important;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .bus-park-block .swiper-slide .card .card-title,
    .bus-park-block .swiper-slide .card .card-text {
        min-height: 48px;
    }

    .custom-me {
        margin-top: 25px !important;
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-bus-page {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 599px) {
    .bus-park-block .swiper-slide .card .card-title,
    .bus-park-block .swiper-slide .card .card-text {
        min-height: inherit;
    }
}

/* @media (max-width: 450px) {
    .common-count-btn span {
        font-size: clamp(1rem, 2vw, 1.5rem) !important;
    }
} */
