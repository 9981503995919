.mySwiper {
  min-height: 325px;
}

.mySwiper video {
  object-position: top;
  object-fit: cover;
  /* height: 325px; */
  /* height: auto;
  aspect-ratio: 10 / 3.2; */
}

.custom-video-block {
  /* max-width: 1190px; */
  /* aspect-ratio: 10 / 3.2; */
  width: 100%;
}

@media screen and (max-width: 992px) {
  .mySwiper {
    /* aspect-ratio: 10 / 3.2; */
    max-height: 325px;
    height: auto;
    min-height: inherit;
  }

  .search-box-block {
    margin-top: -40px !important;
  }
}

@media screen and (max-width: 576px) {

  .mySwiper video {
    /*aspect-ratio: 10 / 3.2;*/
  }

  .search-box-block {
    /*margin-top: -25px !important;*/
  }
}
