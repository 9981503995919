.common-form {
  padding-top: 15px;
}

.common-form * {
  font-weight: 600 !important;
  font-size: 1rem;
}

.common-form .ant-form-item-control-input .ant-select-selector {
  height: 41px;
  align-items: center;
}

.common-form .ant-form-item-control-input .ant-select:hover .ant-select-selector,
.common-form .ant-form-item-control-input .ant-select-focused .ant-select-selector {
  border-color: var(--base-color-green) !important;
}

.common-form .ant-form-item-control-input .ant-select-focused .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(198, 91, 54, .1) !important;
}

.common-form .ant-form-item-control-input .ant-input-number {
  height: 41px;
  display: flex;
  align-items: center;
}

.common-form .ant-form-item-control-input .ant-form-item-control-input-content .ant-input {
  height: 41px;
  display: flex;
  align-items: center;
}

.common-form .react-international-phone-input:hover,
.common-form .react-international-phone-input:focus,
.common-form .ant-form-item-control-input .ant-form-item-control-input-content .ant-input:hover,
.common-form .ant-form-item-control-input .ant-form-item-control-input-content .ant-input:focus {
  border-color: var(--base-color-green);
}

.common-form .react-international-phone-input:focus,
.common-form .ant-form-item-control-input .ant-form-item-control-input-content .ant-input:focus {
  box-shadow: 0 0 0 2px rgba(198, 91, 54, .1);
}

.common-form .ant-form-item-control-input .react-international-phone-input-container .react-international-phone-country-selector {
  height: 39px;
  margin: 1px 0;
  display: flex;
  align-items: center;
}

.react-international-phone-input-container {
  border-radius: 6px;
  border: none !important;
}

.common-form .ant-form-item-control-input .ant-select-selector,
.common-form .ant-form-item-control-input input,
.react-international-phone-country-selector-button {
  height: 41px;
  align-items: center;
}

.common-form .react-international-phone-input {
  width: 100%;
  font-size: 1rem;
}

.common-form .react-international-phone-country-selector-button {
  width: 99px;
  position: absolute;
  background: transparent;
  border-right: none;
  justify-content: flex-start;
  padding-left: 10px;
  border-radius: 6px 0 0 6px;
  border-color: transparent !important;
}

.common-form .react-international-phone-input {
  padding-left: 50px;
  border-radius: 6px;
}

.common-form .react-international-phone-country-selector-button__dropdown-arrow {
  display: none
}

.common-form .ant-form-item-control-input .react-international-phone-input-container .react-international-phone-input {
  height: 41px;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.common-form .ant-form-item-label .ant-form-item-required {
  font-size: 14px;
}

.common-form .ant-form-item-explain .ant-form-item-explain-error,
.ant-form-item-explain-success {
  font-size: 15px;
}

.common-form .ant-input-number {
  width: 100% !important;
}

.common-form .ant-input-number .ant-input-number-handler:hover svg {
  color: var(--base-color-green) !important;
}

.common-form .ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.common-form .ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--base-color-green) !important;
}

.common-form .ant-input-number:focus,
.common-form .ant-input-number:hover,
.common-form .ant-input-number-focused {
  border-color: var(--base-color-green);
}

.common-form .ant-input-number:focus,
.common-form .ant-input-number-focused {
  box-shadow: 0 0 0 2px rgba(198, 91, 54, .1);
}

.common-arrow-btn {
  background: var(--base-color-green);
  padding: 0;
  height: inherit;
  border-radius: 10px;
  border-color: var(--base-color-green) !important;
}

.common-arrow-btn .anticon {
  font-size: 26px;
  color: #ffffff;
  padding: 4px;
}

.common-arrow-btn .anticon svg {
  position: relative;
}

.common-arrow-btn .anticon.anticon-caret-left svg {
  left: -2px !important;
}

.common-arrow-btn .anticon.anticon-caret-right svg {
  right: -2px !important;
}

.common-arrow-btn:hover {
  background: var(--text-color-darkgrey);
  border-color: var(--text-color-darkgrey) !important;
  transition: var(--base-transition) !important;
}

.ant-tabs-nav-list {
  padding: 0 32px;
}

.ant-tabs-nav::before {
  border-color: #999999 !important;
}

.cargo-outer {
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  padding: 2.5rem 2rem 1.25rem 1.25rem !important
}

.cargo-outer .row .col {
  padding: 0;
}

.cargo-outer .cargo-outer-gap {
  gap: 2.25rem;
}

.cargo-outer .cargo-confirm-item-info h3 {
  color: #7A889F;
  font-size: 1rem;
  letter-spacing: .02rem;
}

.cargo-outer .cargo-confirm-item-info h3,
.cargo-outer .cargo-confirm-item-info h4 {
  margin-bottom: 3px;
  line-height: 1;
}

.cargo-outer .cargo-confirm-item-info h4 {
  font-size: 19px;
  font-weight: 600;
}

/* .cargo-outer .cargo-outer-row {
  margin-bottom: 35px !important;
} */

.cargo-outer .cargo-outer-row:last-child {
  margin-bottom: 15px !important;
}

.cargo-outer .sr-switch.ant-switch {
  height: 34px;
  width: 60px;
}

.cargo-outer .sr-switch.ant-switch.ant-switch-checked {
  background: var(--base-color-green);
}

.cargo-outer .sr-switch.ant-switch:hover {
  background: var(--base-color-green) !important;
}

.cargo-outer .sr-switch.ant-switch .ant-switch-handle,
.cargo-outer .sr-switch.ant-switch .ant-switch-handle:before {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.cargo-outer .sr-switch.ant-switch .ant-switch-handle {
  inset-inline-start: 3px;
}

.cargo-outer .sr-switch.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 33px) !important;
}

.cargo-outer .ant-radio-group .ant-radio .ant-radio-inner {
  width: 25px;
  height: 25px;
}

.cargo-outer .ant-radio-group .ant-radio .ant-radio-inner {
  border: 2px solid #989898;
}

.cargo-outer .ant-radio-group .ant-radio-wrapper-checked .ant-radio .ant-radio-inner {
  border-color: var(--base-color-green);
  background-color: var(--base-color-green);
}

.cargo-outer .ant-radio-group .ant-radio .ant-radio-inner:after {
  transform: scale(1.3);
  background: var(--base-color-green);
  border: 3px solid #ffffff;
}

.ant-radio-group .ant-space .ant-space-item .ant-radio-wrapper {
  position: relative;
  display: flex;
  margin-right: 0 !important;
}

.cargo-outer .ant-radio-group .ant-radio-wrapper .ant-radio {
  position: absolute;
  top: 6px;
  left: 0;
}

.cargo-outer .ant-radio-group .ant-radio-wrapper span.ant-radio+* {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}

.cargo-outer p.pvalue {
  margin-right: 15px !important;
}

@media screen and (max-width: 768px) {
  .cargo-outer p.pvalue {
    padding: 4px 0px;
  }
}

/* @media screen and (max-width: 374px) {
  .payment-row .sr-switch {
    margin-left: 50px;
  }

  .payment-row .payment-logos {
    margin-left: 50px;
  }
} */

.cargo-outer .ant-radio-group .ant-radio-wrapper-checked .ant-radio-checked span:hover {
  border-color: var(--base-color-green);
}

.cargo-outer .ant-radio-group .ant-radio-wrapper-checked .ant-radio-checked::after {
  content: "";
  display: none;
}

.cargo-outer .ant-radio-group .ant-radio-wrapper:hover span {
  border-color: var(--base-color-green);
}

.cargo-outer .outer-custom-border:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #DFDFE6;
  margin: 20px 0 15px;
  display: inline-block;
}

.cargo-outer .ant-radio-wrapper p {
  margin: 0;
}

.cargo-outer .payment-row p {
  font-size: 1rem;
  margin-left: 50px;
  min-width: 100px;
  color: #32343F;
}

.cargo-outer .cargo-radio-img {
  width: 55px;
  height: auto;
}

.cargo-outer .payment-row h4 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 15px;
}

.cargo-outer .outer-agreement {
  margin-bottom: 50px;
}

.outer-agreement p {
  max-width: 55%;
}

.cargo-outer .ant-divider {
  margin-left: 62px;
  width: calc(100% - 62px);
  min-width: auto !important;
}

.down-outlined-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-left: 3px solid #32343F;
  border-bottom: 3px solid #32343F;
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
  right: 5px;
}

@keyframes vertShake {
  0% {
    transform: translateY(2px);
  }

  25% {
    transform: translateY(-3px);
  }

  50% {
    transform: translateY(2px);
  }

  75% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0px);
  }

}

.shaker .ant-radio.ant-wave-target {
  animation: vertShake 0.3s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  perspective: 1000px;
}

.shaker .ant-radio.ant-wave-target .ant-radio-inner {
  border-color: red;
}

.common-count-btn {
  gap: 0.5rem;
}

.common-count-btn .ant-btn {
  border-color: #999999;
  width: 32px;
  height: 32px;
  padding: 0;
  color: #32343F !important;
}

.common-count-btn .ant-btn:hover {
  border-color: var(--base-color-green) !important;
  background: var(--base-color-green);
  color: #ffffff !important;
  transition: var(--base-transition);
}

.common-count-btn span {
  font-size: clamp(1rem, 2.5vw, 1.5rem) !important;
  font-weight: 700;
  line-height: 1;
}

.common-count-btn .count-bag {
  width: 26px;
  text-align: center;
  color: #32343F !important;
}

.phone-not-valid .react-international-phone-input {
  border-color: #ff4d4f !important;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* .common-count-btn span {
    font-size: 1.1rem;
  } */
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .custom-me {
      flex-direction: column !important;
    }

    /* .common-count-btn span {
      font-size: 1rem;
    } */

    .custom-me svg {
      width: 25px !important;
      height: 25px !important;
    }
}