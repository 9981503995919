@import '../../constants/constants.css';
@import '../../index.css';

.main-link-arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-right: 2px solid var(--base-color-green);
  border-top: 2px solid var(--base-color-green);
  margin-left: 5px;
}

.navbar-brand {
  display: inline-block !important;
}

.resend-link {
  border-radius: 5px;
  color: var(--text-color-graphite);
  border: 1px solid var(--base-color-green);
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: none;
  padding: 0.125rem 1rem;
  transition: var(--base-transition);
}

.resend-link svg .st0 {
  fill: var(--base-color-green) !important;
}

.resend-link:hover {
  border-color: var(--base-color-green);
  color: var(--base-color-green);
}

.travel-link {
  border-radius: 30px;
  border: 2px solid var(--base-color-green);
  text-decoration: none;
  color: var(--text-color-graphite);
  padding: 0.375rem 1.25rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.travel-link:hover {
  border-color: var(--base-color-blue);
  background: var(--base-color-blue);
  color: #ffffff;
}

.travel-link:hover .main-link-arrow {
  border-right-color: #ffffff;
  border-top-color: #ffffff;
}

.travel-link svg {
  margin-right: 10px;
}

.travel-link svg .st0 {
  fill: var(--base-color-green);
}

.travel-link:hover svg .st0 {
  fill: #ffffff;
}

.nav-lang.navbar-collapse {
  flex-grow: initial;
}

.nav-lang-item {
  display: flex !important;
  align-items: center;
  padding: 15px !important;
  color: var(--base-color-blue) !important;
}

.nav-lang-item img {
  margin-right: 10px;
}

.nav-lang-item:hover {
  background-color: var(--base-color-green) !important;
  color: #ffffff !important;
}

.nav-lang .dropdown-menu {
  padding: 0 !important;
  overflow: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
  right: 0 !important;
  left: inherit !important;
}

.nav-lang .dropdown-toggle {
  color: var(--text-color-graphite);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-lang .dropdown-toggle:hover {
  color: var(--base-color-green);
}

.nav-lang .dropdown-toggle:focus {
  color: var(--base-color-blue);
}

.nav-lang .dropdown-toggle::after {
  margin-left: .8rem !important;
  border-top: 0.45em solid;
  border-right: 0.45em solid transparent;
  border-left: 0.45em solid transparent;
  color: var(--base-color-green);
}

.style-main-block h6 {
  text-transform: uppercase;
  font-weight: 700;
  text-indent: 20px;
}

.friendly-block-style,
.safety-block-style,
.seat-block-style {
  padding: 20px 10px;
  border: 1px solid #D0D0D0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  text-wrap: balance;
}

.custom-card-style {
  padding: 20px 10px !important;
  border: 1px solid #D0D0D0;
  border-radius: 10px;
  height: 100%;
}

.friendly-block-style p,
.safety-block-style p,
.seat-block-style p {
  /* width: 80%; */
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.friendly-block-style h6,
.safety-block-style h6,
.seat-block-style h6 {
  color: var(--base-color-green);
  line-height: inherit;
}

.friendly-block-style img,
.safety-block-style img,
.seat-block-style img {
  height: 30px;
}


.tickets-button-block {
  margin-top: -70px;
  margin-bottom: 40px;
  z-index: 5;
  position: relative;
  width: 100%;
}

/*.main-ticket-style {*/
/*  border: 1px solid #CFD0CF !important;*/
/*  border-radius: 0 10px 10px 0 !important;*/
/*  position: relative;*/
/*  width: calc(100% + 33px);*/
/*  left: -33px;*/
/*}*/

.main-ticket-style.ticket-style,
.main-ticket-style.cargo-style {
  border-radius: 10px !important;
}

.main-ticket-style.ticket-style:before {
  content: '';
  width: 44px;
  height: 44px;
  background: url("../../assets/img/bus_white.svg") center no-repeat;
  position: absolute;
  left: 25px;
  z-index: 5;
}

.main-ticket-style.ant-radio-button-wrapper-checked.ticket-style:before,
.main-ticket-style.ant-radio-button-wrapper-checked.ticket-style:hover:before {
  background: url("../../assets/img/bus_blue.svg") center no-repeat !important;
  z-index: 5;
}

.main-ticket-style.cargo-style:hover:before {
  background-color: transparent !important;
}

.main-ticket-style.cargo-style:before {
  content: '';
  width: 44px;
  height: 44px;
  background: url("../../assets/img/cargo_white.svg") center no-repeat;
  position: absolute;
  left: 25px;
  z-index: 5;
}

.main-ticket-style.ant-radio-button-wrapper-checked.cargo-style:before,
.main-ticket-style.ant-radio-button-wrapper-checked.cargo-style:hover:before {
  background: url("../../assets/img/cargo_blue.svg") center no-repeat !important;
  z-index: 5;
}

.main-ticket-style.cargo-style:hover:before {
  background-color: transparent !important;
}


.main-ticket-style.ant-radio-button-wrapper-checked {
  z-index: inherit;
}

.main-ticket-style.ant-radio-button-wrapper-checked .ant-radio-button-checked {
  border: 1px solid #CFD0CF !important;
  border-left: none !important;
  border-radius: 0 10px 10px 0 !important;
  position: absolute;
  width: calc(100% + 26px);
  left: -26px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 3px 8px 10px -8px rgba(150, 150, 150, 1);
}

.main-ticket-style.ant-radio-button-wrapper-checked .ant-radio-button-checked:before {
  content: '';
  width: 30px;
  height: 30px;
  background: #ff000000;
  position: absolute;
  z-index: -4;
  box-shadow: inset 1px 1px 0px 0px rgb(207 208 207);
  border-radius: 20px 0 0;
  border-left: 10px solid #ffffff;
  border-top: 10px solid #ffffff;
}

/*.main-ticket-style.ant-radio-button-wrapper-checked .ant-radio-button-checked:after {*/
/*  content: '';*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  background: red;*/
/*  position: absolute;*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  z-index: -4;*/
/*  box-shadow: inset 1px 1px 0px 0px rgb(207 208 207);*/
/*}*/

.main-ticket-style.ant-radio-button-wrapper-checked.ticket-style .ant-radio-button-checked:before {
  left: -9px;
  bottom: -20px;
}

.main-ticket-style.ant-radio-button-wrapper-checked.cargo-style .ant-radio-button-checked:before {
  left: -9px;
  top: -20px;
  transform: rotateX(180deg);
}

/*.safety-block-style,*/
/*.seat-block-style {*/
/*  width: calc(50% - 1px) !important;*/
/*}*/

.friendly-block-style {
  border-radius: 10px;
}

.safety-block-style {
  border-radius: 10px;
}

.seat-block-style {
  border-radius: 10px;
}

.icon-position {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 15px;
}

.icon-position.icon_checkmark_outlined svg g,
.icon-position.icon_checkmark_outlined svg .st0 {
  fill: var(--base-color-green) !important;
}

.download-block-style h5 {
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: uppercase;
}

.download-block-style ul {
  padding: 0 !important;
  margin: 0 !important;
}

.download-block-style li {
  list-style: none;
  margin: 0 10px;
}

.download-block-style li.store-img {
  padding: 10px 0;
}

.banner-block {
  border: 1px solid var(--base-color-blue);
  border-radius: 10px;
  padding: 0 !important;
  overflow: hidden;
}

.banner-block-style {
  padding: 25px !important;
  overflow: hidden;
  background: url('../../assets/img/image_banner.png') center no-repeat;
  background-size: cover;
  height: 240px;
  color: var(--text-color-white);
}

.banner-block-style h4 {
  text-transform: uppercase;
}

.banner-block-style h4,
.banner-block-style p {
  padding-left: 25px;
}

.banner-block-style h4 {
  font-size: 1.5rem;
}

.download-block-style {
  border-top: 1px solid var(--base-color-blue);
  padding: 12px;
  display: flex;
  align-items: center;
}

.main-text-info-block {
  position: relative;
  padding: 10px 10px 10px;
}

.main-text-info-block h4 {
  padding-left: 30px;
  margin-bottom: 10px;
  padding-right: 50px;
  font-size: 1.25rem;
}

.main-text-info-block p {
  margin-bottom: 0;
  line-height: 1.375rem;
  font-size: 0.875rem;
  margin-left: 30px;
}

.main-text-info-block .icon-position {
  left: 0;
  top: 8px;
}

.top-destinations-block {
  background: var(--base-color-blue);
  color: var(--text-color-white);
  font-weight: 500;
  padding: 40px 0 70px;
}

.top-destinations-block h4 {
  font-weight: 700;
  margin-bottom: 50px;
  font-size: 1.25rem;
}

.destinations-list {
  margin-bottom: 15px;
}

.destinations-list a {
  text-decoration: none;
  color: var(--text-color-white);
  font-size: 1rem;
  cursor: pointer;
}

.destinations-list a:hover,
.find-us-block ul li a:hover {
  color: var(--base-color-green) !important;
  transition: var(--base-transition);
}

.find-us-block {
  padding: 60px 0 135px;
}

.find-us-block ul {
  padding-left: 0;
}

.find-us-block ul li {
  list-style: none;
  margin-bottom: 10px;
}

.find-us-block h6 {
  color: var(--base-color-blue);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}

.find-us-block h4 {
  color: var(--text-color-graphite);
  font-weight: 700;
  text-align: right;
  margin-bottom: 50px;
  font-size: 1.25rem;
}

.find-us-block ul li a {
  text-decoration: none;
  color: var(--base-color-blue);
  font-weight: 500;
}

.social-menu li a svg {
  color: var(--text-color-white) !important;
  height: 20px;
  width: auto;
}

.social-menu li a:hover svg {
  height: 24px;
  transition: var(--base-transition);
}

.find-us-block .social-menu li {
  margin-bottom: 0;
}

.social-menu li a {
  border-radius: 50%;
  height: 40px;
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  color: var(--text-color-white) !important;
}

.social-menu li .facebook:hover {
  font-size: 24px;
  transition: var(--base-transition);
  color: var(--text-color-white) !important;
}

.social-menu .facebook {
  background: #355492;
  font-weight: 700;
  font-size: 20px;
}

.social-menu .twitter {
  background: #32CCFF;
}

.social-menu .instagram {
  background: #B02AC4;
}

.social-menu .google {
  background: #D44132;
}

.txt-box {
  margin-bottom: 5px;
  padding: 20px 20px 20px 0;
}

.txt-box p:last-child {
  margin-bottom: 0;
}

.txt-block h5 {
  margin-bottom: 20px;
}

.w-700 {
  font-weight: 700;
}

.s-24 {
  font-size: 1.5rem;
}

/* .achievements-block h5 {
  padding-left: 35px;
} */

@media screen and (max-width: 768px) {
  .style-block-row {
    gap: 15px;
  }

  .txt-box {
    padding: 0;
  }

  .achievements-block h5 {
    padding-left: 0;
}

  .main-text-info-block h4 {
    padding-left: 25px;
  }
  
  .main-text-info-block p {
    margin-left: 25px;
  }
  
  .main-text-info-block .icon-position {
    left: 0;
  }
}

@media screen and (max-width: 991px) {
  .friendly-block-style img, .safety-block-style img, .seat-block-style img {
    height: 25px;
  }
}