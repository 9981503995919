.cargo-outer .row .col:last-of-type {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cargo-outer .cargo-type-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cargo-outer .cargo-type-container p {
  margin: 0px;
  font-weight: 600;
  font-size: 20px;
}

.cargo-outer .cargo-type-container .cargo-type-slider {
  width: 36px;
  height: 36px;
  overflow: hidden;
}

.cargo-outer .cargo-type-container .cargo-type-slider svg path {
  fill: var(--base-color-orange);
}

.cargo-outer .cargo-type-container .cargo-type-slider svg.cargo-type-solid path {
  fill: #232529;
}

.cargo-outer .cargo-type-icon {
  width: 36px;
  height: 36px;
}

.cargo-birthday {
  display: flex;
  align-items: baseline;
}

.cargo-tab-item {
  border: 1px solid var(--base-color-orange);
  padding: 0.75rem 2rem;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1rem;
  color: var(--base-color-orange);
  position: relative;
  transition: ease-in-out 0.15s;
  overflow: hidden;
}

.ant-tabs-tab-active .cargo-tab-item {
  background-color: var(--base-color-orange);
  color: #fff;
  transition: ease-in-out 0.15s;
}

.cargo-tab-item:hover {
  background-color: var(--base-color-blue);
  border-color: var(--base-color-blue);
  color: #fff;
  transition: ease-in-out 0.15s;
}

.cargo-tab-tick svg {
  padding-right: 4px;
  margin-left: 4px;
}
.sr-manual .cargo-tab-tick-appear svg {
  padding-right: 6px;
  margin-left: 3px;
}
.sr-manual .cargo-tab-tick-appear.bg-danger svg {
  padding-right: 6px;
  margin-left: 4px;
}
.sr-manual .cargo-tab-tick-appear.left svg {
  padding-right: 6px;
  margin-left: 4px; 
}
.sr-manual .cargo-tab-tick-appear.left.bg-danger svg {
  padding-right: 6px;
  margin-left: 4px; 
}

.cargo-tab-item .cargo-tab-tick .anticon {
  margin-right: 0;
}

.cargo-tab-tick-appear {
  opacity: 1;
  transition: ease-in-out 0.5s;
}

.cargo-tab-tick-disappear {
  position: absolute;
  top: -1px;
  right: -1px;
  opacity: 0;
  width: 20px;
  height: 20px;
  transition: ease-in-out 0.5s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0px 9px;
}

.cargo-tab-tick-disappear.left {
  position: absolute;
  top: -1px;
  left: -1px;
  opacity: 0;
  width: 20px;
  height: 20px;
  transition: ease-in-out 0.5s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 9px 0px;
}

.cargo-tab-tick-disappear svg {
  padding-right: 6px;
  margin-left: 4px;
}

.cargo-tab-item .cargo-tab-tick {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #1fa79c;
  color: white;
  top: -1px;
  right: -1px;
  border-radius: 0px 9px;
}

.sr-manual .cargo-tab-item .cargo-tab-tick {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #1fa79c;
  color: white;
  top: -2px;
  right: -2px;
  border-radius: 0px 9px;
}

.cargo-tab-item .cargo-tab-tick.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #1fa79c;
  color: white;
  top: -1px;
  left: -1px;
  border-radius: 9px 0px;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-nav {
  padding: 1.5rem 0rem;
}

.ant-tabs .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.cargo-form-element {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  position: relative;
}

.cargo-form-element label {
  padding-left: 5px;
  color: gray;
}

.sr-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
}

.sr-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left-border {
  border-radius: 10px 0px 0px 10px;
  padding: 10px 40px;
}

.right-border {
  border-radius: 0px 10px 10px 0px;
  padding: 10px 40px;
}

.sr-manual {
  margin: 24px 0px;
}

.sr-manual button {
  font-size: 1rem;
  font-weight: 600 !important;
  background-color: white;
}

.sr-manual button:first-of-type {
  border: 1px solid var(--base-color-orange);
  border-right: 0px;
  transition: ease-in-out 0.15s;
  overflow: hidden;
}

.sr-manual button:last-of-type {
  border: 1px solid var(--base-color-orange);
  border-left: 0px;
  transition: ease-in-out 0.15s;
  overflow: hidden;
}

.sr-manual button:hover {
  color: #fff;
  background-color: var(--base-color-blue);
  border-color: var(--base-color-blue);
  transition: ease-in-out 0.15s;
}

.sr-manual button.bg-var-orange:hover {
  color: #fff;
  background-color: var(--base-color-blue);
  border-color: var(--base-color-blue);
  transition: ease-in-out 0.15s;
}

.sr-manual button.bg-var-orange {
  background-color: var(--base-color-orange);
  color: #fff;
}

.font-orange {
  color: var(--base-color-orange);
}

.col-md-6 .react-international-phone-country-selector-button__dropdown-arrow {
  display: none;
}

.col-md-6 .react-international-phone-country-selector-button {
  border-right: 0px;
}

.sr-mail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sr-mail-icon {
  width: 36px;
  height: 36px;
}

.sr-mail-inner {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sr-mail-inner p {
  margin-bottom: 0px;
}

.sr-mail-container .ant-form-item {
  margin-bottom: 0px;
}

.cargo-outer .cargo-fragil-img svg .st0 {
  fill: var(--base-color-orange) !important;
}

.cargo-type-confirm-icon {
  width: 52px;
  height: 52px;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-100 .cargo-inner-data {
  max-height: 410px;
  overflow-y: auto;
}