@import '../../constants/constants.css';

.footer-block {
	color: var(--text-color-white);
}

.subscribe-block {
	background: var(--base-color-green);
	/*height: 110px;*/
}

.subscribe-block h6 {
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 10px;
}

.subscribe-block form .ant-form-item {
	margin-right: 0 !important;
	/*margin-left: 20px;*/
}

#subscribe {
	position: relative;
	border-radius: 50px;
	overflow: hidden;
	margin-left: 50px;
}

#subscribe,
.subscribe-block form .form-imput {
	width: 100%;
}

.subscribe-block form .form-imput .ant-col #subscribe_email_help {
	position: absolute;
	bottom: -4px;
	left: 35px;
}

.subscribe-block form .form-button {
	position: absolute;
	right: 0;
	top: 0;
	height: 50px;
}

.subscribe-block form .form-button .ant-btn {
	height: 50px;
	border-radius: 50px;
	padding: 0 35px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.75rem;
	background: #636363;
}

.subscribe-block form .form-button .ant-btn:hover {
	background: #636363;
	color: var(--base-color-green);
	transition: var(--base-transition);
}

#subscribe_email {
	height: 50px;
	background: #fff;
	border: none;
	color: var(--text-color-graphite);
	font-size: 15px;
	padding-left: 20px;
}

#subscribe_email::placeholder {
	color: var(--text-color-graphite);
}

.custom-border-el {
	position: relative;
}

.custom-border-el:before {
	content: '';
	width: 1px;
	height: 100%;
	background: #1a5c86;
	position: absolute;
	left: -40px;
}

.footer-menu-list {
	height: 110px;
}

.footer-menu-list a,
.footer-menu-list p {
	color: var(--text-color-graphite);
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 0;
}

.footer-menu-list a {
	text-decoration: none;
}

.footer-menu-list a:hover {
	color: var(--base-color-green);
	transition: var(--base-transition);
}

.footer-menu-list ul {
	padding-left: 0;
	margin-bottom: 0;
}

.footer-menu-list ul li {
	list-style: none;
	margin-right: 30px;
}

.footer-block .cutom-footer-logo {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 768px) {
	#subscribe {
		/* margin-left: 0 !important; */
		margin: 20px 10px 10px;
	}

  .footer-menu-list a, .footer-menu-list p {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
	#subscribe {
		margin-left: 15px;
	}

	.subscribe-block form .form-button .ant-btn {
		padding: 0 20px;
		font-size: 0.6rem;
	}

	#subscribe_email {
		font-size: 13px;
	}
}

@media screen and (max-width: 320px) {
	#subscribe_email {
		padding-left: 10px;
	}

	.subscribe-block form .form-button .ant-btn {
		padding: 0 10px;
		font-size: 0.55rem;
	}

	#subscribe_email {
		font-size: 12px;
	}

  .footer-menu-list a, .footer-menu-list p {
    font-size: .74rem;
  }
}
