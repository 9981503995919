@import '../../constants/constants.css';

.book-header-block {
  background-color: var(--base-color-grey);
  color: var(--text-color-darkgrey);
  padding: 50px;
  font-size: 20px;
}

.back-button-style {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.back-button-style .anticon {
  margin-right: 10px;
}

.back-button-style:hover {
  color: var(--base-color-green);
}

.book-header-block .items-date-block:before {
  background: url("../../assets/img/icon_time.svg") center no-repeat;
}

.book-header-block .items-direction:before {
  background: url("../../assets/img/icon_bus.svg") center no-repeat;
}

.book-header-block .results-items .anticon {
  border: 2px solid var(--base-color-green);
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  bottom: 32px;
  left: -35px
}

.book-header-block .ant-statistic * {
  color: #ffffff !important;
}

.book-header-block .ant-statistic-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.book-header-block .ant-statistic-content {
  font-size: 26px;
  font-weight: 700;
}

.book-header-block .destination-result {
  border: 1px solid #ffffff;
  background: #fff;
  border-radius: 5px;
  padding: 20px !important;
  height: 100%;
}

.book-header-block .timer-block {
  background: var(--base-color-green);
}

.book-header-block .ant-statistic {
  position: relative;
  min-width: 170px;
  text-align: center;
}

.book-header-block .ant-statistic:before {
  content: '';
  width: 15%;
  /* height: 12.; */
  aspect-ratio: 1;
  left: -10px;
  bottom: 7px;
  position: absolute;
  display: inline-block;
  background: url("../../assets/img/icon_timer.svg") center no-repeat;
}

.bus-floor-type {
  text-transform: uppercase;
  text-align: center;
  color: var(--base-color-blue);
  margin-bottom: 30px;
}

.seats-color-legend .legend-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
}

.legend-available,
.legend-selected,
.legend-booked {
  position: relative;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.legend-booked {
  margin-right: 0 !important;
}

.legend-available:before,
.legend-selected:before,
.legend-booked:before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border: 1px solid #000000;
  border-radius: 7px;
  position: relative;
}

.legend-available:before {
  background-color: #F5F6FC;
}

.legend-selected:before {
  background-color: var(--base-color-orange);
}

.legend-booked:before {
  background-color: #316BBF;
}

.cart-seat-info {
  min-height: 200px;
  padding: 20px;
  background-color: #f0f0f5;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .row .less-px h5 {
    font-size: 1rem !important;
  }

  .total-ticket-price h3 {
    font-size: 1.18rem !important;
  }

  .cargo-outer .cargo-confirm-item-info h4 {
    font-size: 17px !important;
  }

  .cargo-outer .cargo-confirm-item-info h3 {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 992px) {
  .row .less-px {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .seats-color-legend {
    padding-top: 20px;
  }

  .book-header-block .results-items .anticon {
    bottom: 22px;
    left: 4px;
  }

  .row .less-px {
    width: fit-content;
  }

  .destination-col {
    margin-bottom: 16px;
  }

  .cargo-outer .cargo-confirm-item-info.custom-mobile-padding h3,
  .cargo-outer .cargo-confirm-item-info.custom-mobile-padding h4 {
    padding-left: 36px !important;
  }

  .cargo-confirm-item-info {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 400px) {
  .passenger-confirm-block .premium-ticket svg {
    height: 45px;
  }

  .cargo-outer.passenger-confirm-block {
    padding: 30px 5px !important;
  }

  .cargo-confirm-item-info {
    padding-left: 5px !important;
  }

  .cargo-outer .ant-divider {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .cargo-outer .payment-row p {
    max-width: inherit !important;
    padding-right: 16px;
    margin-left: 41px !important;
  }

  .payment-row .sr-switch {
    margin-left: 41px;
  }

  .payment-row .payment-logos {
    margin-left: 41px;
  }
}

@media screen and (max-width: 470px) {
  .book-header-block {
    padding: 50px 16px;
  }

  .book-header-block .destination-result {
    padding: 15px !important;
  }

  .strong-result-text {
    font-size: 1rem;
  }

  .items-date-block, .items-direction {
    padding-left: 25px !important;
  }

  .items-arrival-block {
    padding-left: 37px !important;
  }

  .items-date-block:before, .items-direction:before {
    width: 23px;
    height: 23px;
  }

  .book-header-block .results-items .anticon {
    width: 23px;
    height: 23px;
  }
}

.cart-seat-info h5 {
  margin-bottom: 20px;
}

.cart-seat-info h4 {
  font-size: 1.375rem;
}

.total-ticket-price {
  padding: 0px 20px 10px;
}

.total-ticket-price h3 {
  font-weight: 700;
  border-top: 2px solid #DCDCE3;
  padding-top: 30px;
  font-size: 1.5rem;
}

.cart-next-btn {
  padding: 10px 20px 20px;
}

.cart-next-btn .book-now-button {
  width: 100%;
  background-color: var(--base-color-green);
  color: #ffffff;
  font-size: 1.25rem;
  height: inherit;
  border: none;
  padding: 10px;
  border-radius: 30px;
  font-weight: 700;
}

.cart-next-btn .book-now-button:hover {
  background-color: var(--text-color-darkgrey)
}

@keyframes shake {
  0% {
    transform: rotate(5deg);
  }

  25% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.book-trigger-animation {
  color: red;
  animation: shake 0.3s cubic-bezier(.36, .07, .19, .97) both;
  backface-visibility: hidden;
  perspective: 1000px;
}